import React, { useEffect, useState } from "react";
import {
  Card,
  FluentProvider,
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  Spinner,
  webLightTheme,
} from "@fluentui/react-components";
import Header from "./Header";
import "./App.css";
import LinkGroup, { AkaItem } from "./LinkGroup";
import { PublicClientApplication } from "@azure/msal-browser";
import {
  MsalProvider,
  useMsal,
  useIsAuthenticated,
  MsalAuthenticationTemplate,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

type DisplayMode = "loaded" | "loading" | "unauthenticated";

const backgroundImages = [
  "images/bg-001.jpg",
  "images/bg-002.jpg",
  "images/bg-003.jpg",
  "images/bg-004.jpg",
  "images/bg-005.jpg",
  "images/bg-006.jpg",
  "images/bg-007.jpg",
  "images/bg-008.jpg",
  "images/bg-009.jpg",
  "images/bg-010.jpg",
];

const getRandomBackgroundImage = () => {
  const randomIndex = Math.floor(Math.random() * backgroundImages.length);
  return backgroundImages[randomIndex];
};

const backgroundStyle = {
  backgroundImage: `url(${getRandomBackgroundImage()})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundAttachment: "fixed",
  height: "100vh",
  width: "100vw",
  top: 0,
  left: 0,
  zIndex: -1,
};

const groupItemsByGroup = (items: AkaItem[]): Record<string, AkaItem[]> => {
  return items.reduce((groups: Record<string, AkaItem[]>, item: AkaItem) => {
    const group = item.group;
    if (!groups[group]) {
      groups[group] = [];
    }
    groups[group].push(item);
    return groups;
  }, {});
};

const msalConfig = {
  auth: {
    clientId: "2769168a-5521-476b-9bd1-af2577f6e509",
    authority:
      "https://login.microsoftonline.com/c153a817-2f31-469a-b130-47d09159a9f6",
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
  },
};

const msalLoginRequest = {
  scopes: ["api://7741658e-5450-4fd9-adca-fd2d0af1e0ad/Items.Read"],
};

const msalInstance = new PublicClientApplication(msalConfig);

const AppContent = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [items, setItems] = useState<AkaItem[]>([]);
  const [displayMode, setDisplayMode] = useState<DisplayMode>("loading");

  useEffect(() => {
    setDisplayMode("loading");
    if (!isAuthenticated) {
      return;
    }

    const fetchItems = async () => {
      try {
        const account = accounts[0];
        const response = await instance.acquireTokenSilent({
          ...msalLoginRequest,
          account,
        });
        const token = response.accessToken;

        const apiResponse = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/items`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!apiResponse.ok) {
          throw new Error("Failed to fetch items");
        }

        const data: AkaItem[] = await apiResponse.json();
        setItems(data);
      } catch (error) {
        console.error("Error fetching items:", error);
      } finally {
        setDisplayMode("loaded");
      }
    };

    fetchItems();
  }, [accounts, instance, isAuthenticated]);

  const groupedItems = groupItemsByGroup(items);

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <div style={backgroundStyle}>
        <div className="centered-container">
          <Card>
            <Header />
            {displayMode === "loading" && <Spinner />}
            {displayMode === "unauthenticated" && (
              <MessageBar key="error" intent="error">
                <MessageBarBody>
                  <MessageBarTitle>Unauthorized</MessageBarTitle>
                  Please reload the page to try again.
                </MessageBarBody>
              </MessageBar>
            )}
            {displayMode === "loaded" &&
              Object.keys(groupedItems).map((group) => (
                <LinkGroup
                  key={group}
                  title={group}
                  items={groupedItems[group]}
                />
              ))}
          </Card>
        </div>
      </div>
    </MsalAuthenticationTemplate>
  );
};

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <FluentProvider theme={webLightTheme}>
        <AppContent />
      </FluentProvider>
    </MsalProvider>
  );
}

export default App;
