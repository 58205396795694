import { Image } from "@fluentui/react-components";
import "./Header.css";
import Divider from "./Divider";

const Header = () => {
  return (
    <div>
      <div className="centered-image-container" style={{ paddingTop: "20px" }}>
        <Image src="images/dalog_logo.svg" height={80} />
      </div>
      <div className="centered-image-container">
        <Divider height={10} />
      </div>
    </div>
  );
};

export default Header;
