import * as React from "react";
import {
  Divider,
  Title3,
  DataGridBody,
  DataGridRow,
  DataGrid,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  TableCellLayout,
  TableColumnDefinition,
  createTableColumn,
  DataGridProps,
  Link,
} from "@fluentui/react-components";

export type AkaItem = {
  title: string;
  description: string;
  link: string;
  shortLink: string;
  group: string;
};

const columns: TableColumnDefinition<AkaItem>[] = [
  createTableColumn<AkaItem>({
    columnId: "title",
    compare: (a, b) => {
      return a.title.localeCompare(b.title);
    },
    renderHeaderCell: () => {
      return "Title";
    },
    renderCell: (item) => {
      return <TableCellLayout>{item.title}</TableCellLayout>;
    },
  }),
  createTableColumn<AkaItem>({
    columnId: "description",
    compare: (a, b) => {
      return a.description.localeCompare(b.description);
    },
    renderHeaderCell: () => {
      return "Description";
    },
    renderCell: (item) => {
      return <TableCellLayout>{item.description}</TableCellLayout>;
    },
  }),
  createTableColumn<AkaItem>({
    columnId: "shortLink",
    compare: (a, b) => {
      return a.shortLink.localeCompare(b.shortLink);
    },
    renderHeaderCell: () => {
      return "Link";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout>
          <Link
            target="_blank"
            href={`https://aka.dalog.net/${item.shortLink.replace("//", "")}`}
          >
            {item.shortLink}
          </Link>
        </TableCellLayout>
      );
    },
  }),
];

const columnSizingOptions = {
  title: {
    defaultWidth: 180,
  },
  description: {
    defaultWidth: 400,
  },
  shortLink: {
    defaultWidth: 120,
  },
};

export interface LinkGroupProps {
  title?: string;
  items?: AkaItem[];
}

const LinkGroup: React.FC<LinkGroupProps> = (props) => {
  const defaultSortState = React.useMemo<
    Parameters<NonNullable<DataGridProps["onSortChange"]>>[1]
  >(() => ({ sortColumn: "title", sortDirection: "ascending" }), []);

  return (
    <div style={{ paddingBottom: "25px" }}>
      <Divider className="link-group-divider">
        <Title3>{props.title}</Title3>
      </Divider>
      <DataGrid
        items={props.items ?? []}
        columns={columns}
        defaultSortState={defaultSortState}
        resizableColumns
        columnSizingOptions={columnSizingOptions}
        resizableColumnsOptions={{
          autoFitColumns: false,
        }}
        style={{ minWidth: "400px" }}
      >
        <DataGridHeader>
          <DataGridRow>
            {({ renderHeaderCell }) => (
              <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
            )}
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody<AkaItem>>
          {({ item, rowId }) => (
            <DataGridRow<AkaItem> key={rowId}>
              {({ renderCell }) => (
                <DataGridCell>{renderCell(item)}</DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
    </div>
  );
};

export default LinkGroup;
