import * as React from "react";
import "./Divider.css";

export interface DividerProps {
  height?: number;
}

const Divider: React.FC<DividerProps> = ({ height }) => {
  return <div className="divider" style={{ height }}></div>;
};

export default Divider;
